<template>
  <div class="footer">
    <div class="wrap">
      <div class="footernav">
        <div class="f_logo">
          <img src="../img/logo2.png" alt="" />
          <p class="address"> AFIGHT TECHNOLOGIES PRIVATE LIMITED</p>
        </div>
        <div class="r">
          <p style="margin-bottom: 40px">H E L P</p>
          <Nav :type="'column'" class="rnav" :hrefList="hrefList"></Nav>
        </div>
      </div>

      <div class="footerinfo">
        <p>© {{ CurrentYean }} AFIGHT TECHNOLOGIES All Rights Reserved.</p>
      </div>
    </div>
  </div>
</template>

<script>
import Nav from '@/components/Nav/Nav.vue';
export default {
  data() {
    return {
      hrefList: [
        'About Us',
        'Contact Us',
        'TERMS AND CONDITIONS',
        'Privacy Policy',
      ],
      CurrentYean: '--',
    };
  },
  created() {
    // 获取当前年
    const yean = new Date().getFullYear();
    this.CurrentYean = yean;
  },
  components: {
    Nav,
  },
};
</script>

<style lang="scss" scoped src="./footer.scss">
</style>